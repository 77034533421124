<template>
<div>
    <a-dropdown :trigger="['click']">
        <div class="ccp-search">
            <a-input class="cpp-search-input"
                     placeholder="输入编码/农资名称搜索"
                     v-model:value="cfdState.search.key"
                     @input="getFarmDataList"
            />
            <i class="ri-more-fill ri-xl" @click.stop="showFarmDataByPage"></i>
        </div>
        <template #overlay>
            <a-menu class="cpp-goods" style="height: 300px;overflow: hidden;overflow-y: auto;">
                <template v-for="(item,index) in cfdState.list" :key="index">
                    <a-menu-item @click="selectFarmData(item)" :disabled="item.kd_checked">
                        <a-space>
                            <span>{{item.bar_code}} </span>
                            <span>{{item.name}}</span>
                        </a-space>
                    </a-menu-item>
                </template>
            </a-menu>
        </template>
    </a-dropdown>

    <a-modal title="选择产品" size="mini" width="900px"  v-model:visible="cfdState.show" @ok="sureSelectProduct" >
        <a-table :pagination="false" rowKey="id" :data-source="fdState.list" :scroll="{ y: 400 }" :columns="[
                {title:'农资图片',dataIndex:'image',slots: {customRender: 'image'}},
                {title:'农资编号',dataIndex:'id'},
                {title:'农资名称',dataIndex:'name'},
            ]" :row-selection="{
                selectedRowKeys:fdState.pks,onChange:(e)=>{fdState.pks = e} ,
                getCheckboxProps: (record) => ({ disabled: record.kd_checked }),
            }">
            <template #image="{record}">
                <div v-if="record.images && record.images.length > 0">
                    <a-image :src="record.images[0]" width="30px"></a-image>
                </div>
            </template>
        </a-table>
        <kd-pager :page-data="fdState" :show-size="false" :show-jumper="true" :event="getFarmDataByPage"></kd-pager>
    </a-modal>

</div>
</template>

<script>
import { useCheckFarmData ,useFarmData} from "@/models/addons/storage"
import tool from "@/util/tool";
export default {
    name: "com-check-farm-data",
    props:{
        index:{
            type:Number
        },
        checkedData:{
            type:[Object,Array]
        }
    },
    setup(props,context){
        let { cfdState,getFarmDataList,fdState,getFarmDataByPage} = useCheckFarmData()
        getFarmDataList()

        if( props.checkedData ){
            let arr = []
            props.checkedData.forEach(item=>arr.push(item.class_id))
            cfdState.checkedData = arr
        }

        function showFarmDataByPage(){
            cfdState.show = true
            getFarmDataByPage(1,fdState.limit)
        }

        function selectFarmData(e){
            context.emit('success',{data:[e],index:props.index})
        }

        function sureSelectProduct(){
            if( !fdState.pks || fdState.pks.length === 0 ){
                tool.message("请选择农资信息","warning")
                return
            }
            let temp = []
            fdState.list.forEach(item=>{
                if( fdState.pks.includes(item.id) ){
                    temp.push(item)
                }
            })
            cfdState.show = false
            context.emit('success', {data:temp,index:props.index})
        }

        return {
            cfdState,getFarmDataList,
            fdState,getFarmDataByPage,
            showFarmDataByPage,selectFarmData,sureSelectProduct
        }
    }
}
</script>

<style scoped lang="scss">
.ccp-con{
    position: relative;
}
.ccp-search{
    display: flex;
    position: relative;
    i{
        position: absolute;
        cursor: pointer;
        z-index: 999;
        right: 8px;
        top: 8px;
    }
}
.ccp-search /deep/ .ant-input{
    border: none !important;
}
</style>

