<template>
<div>
    <a-card title="添加库存盘点">
        <template #extra>
            <a-button @click="$router.go(-1)">返回</a-button>
        </template>

        <div class="mb12">
            <span>盘点仓库：</span>
            <a-select v-model:value="astState.form.depot_id" style="width: 270px" placeholder="选择盘点仓库">
                <a-select-option :value="item.id" v-for="(item,index) in astState.entrepot" :key="index">
                    {{item.name}}
                </a-select-option>
            </a-select>

            <span style="margin-left: 48px;">盘点类型：</span>
            <a-select v-model:value="astState.form.type" style="width: 270px" @change="changeType">
                <a-select-option :value="1">产品</a-select-option>
                <a-select-option :value="2">农资</a-select-option>
            </a-select>
        </div>

        <a-table :pagination="false" row-key="class_id" :data-source="astState.form.data"  :columns="astState.columns">
            <template #action="{record,index}">
                <i class="ri-xl ri-top pt ri-add-line" @click="addOrDelProduct('add',index)"></i>
                <i class="ri-xl ri-top pt ri-subtract-line"
                   @click="addOrDelProduct('del',index)"
                   v-if="astState.form.data.length >= 2"></i>
            </template>
            <template #product="{record,index}">
                <div v-if="record.class_id">
                    <span>{{record.productInfo?.name}}</span>
                    <span>{{record.productInfo?.kd_spec?.model}}</span>
                </div>
                <div v-else>
                    <com-check-product @success="getSelectProduct"
                                       :index="index"
                                       v-if="astState.form.type === 1"
                                       :checked-data="astState.form.data">
                    </com-check-product>
                    <com-check-farm-data @success="getSelectProduct"
                                         :index="index"
                                         v-else
                                         :checked-data="astState.form.data">
                    </com-check-farm-data>
                </div>
            </template>
            <template #sku_id="{record}">
                <a-select v-model:value="record.sku_id"
                          style="width: 120px"
                          v-if="record.productInfo &&record.productInfo.sku && record.productInfo.sku.length >0"
                          placeholder="选择规格"
                >
                    <a-select-option :value="item.id" v-for="(item,index) in record.productInfo.sku" :key="index">
                        {{item.model}}
                    </a-select-option>
                </a-select>
                <span v-else>--</span>
            </template>
            <template #type="{record}">
                <a-select v-model:value="record.type" style="width: 120px">
                    <a-select-option :value="1">产品</a-select-option>
                    <a-select-option :value="2">农资</a-select-option>
                </a-select>
            </template>
            <template #inventory_stock="{record}">
                <a-input v-model:value="record.inventory_stock" style="width: 150px"
                         :addon-after="record.productInfo?.unit?.name"></a-input>
            </template>
            <template #inventory_loss="{record}">
                <a-input v-model:value="record.inventory_loss" style="width: 150px"></a-input>
            </template>
            <template #remark="{record}">
                <a-input v-model:value="record.remark" style="width: 200px"></a-input>
            </template>
        </a-table>
        <div class="mt12">
            <a-textarea v-model:value="astState.form.remark" placeholder="备注" :rows="4" />
        </div>
        <div class="mt12">
            <a-space>
                <a-button type="primary" @click="saveStockTalk">保存盘点结果</a-button>
            </a-space>
        </div>

    </a-card>
</div>
</template>

<script>
import { useAddStockTalk } from '@/models/addons/storage'
// import comSelectProduct from './com-select-product.vue'
// import comSelectFarmData from './com-select-farm-data.vue'
import comCheckProduct from './com-check-product.vue'
import comCheckFarmData from './com-check-farm-data.vue'
export default {
    components:{
        comCheckProduct,
        comCheckFarmData
    },
    setup(){
        let { astState ,deleteTalk,saveStockTalk,getSelectProduct,addOrDelProduct,changeType} = useAddStockTalk()

        return { astState ,deleteTalk,saveStockTalk,getSelectProduct,addOrDelProduct,changeType}
    }
}
</script>

<style scoped>

</style>
