<template>
<div class="ccp-con">
    <a-dropdown :trigger="['click']">
        <div class="ccp-search">
            <a-input class="cpp-search-input"
                     placeholder="输入编码/产品名称搜索"
                     v-model:value="cpaState.search.key"
                     @input="getProductListByAll"
            />
            <i class="ri-more-fill ri-xl" @click.stop="showProductByPage"></i>
        </div>
        <template #overlay>
            <a-menu class="cpp-goods" style="height: 300px;overflow: hidden;overflow-y: auto;">
                <template v-for="(item,index) in cpaState.list" :key="index">
                    <a-menu-item @click="selectProduct(item)" :disabled="item.kd_checked">
                        <a-space>
                            <span>{{item.bar_code}} </span>
                            <span>{{item.name}}</span>
                            <span v-if="item.kd_spec">{{ item.kd_spec.model}}</span>
                        </a-space>
                    </a-menu-item>
                </template>
            </a-menu>
        </template>
    </a-dropdown>

    <a-modal title="选择产品" size="mini" width="900px"  v-model:visible="cpState.show" @ok="sureSelectProduct" >
        <a-table :pagination="false" rowKey="kd_spec_id" :data-source="cpState.list" :scroll="{ y: 400 }" :columns="[
                {title:'产品图片',dataIndex:'image',slots: {customRender: 'image'}},
                {title:'产品编号',dataIndex:'id'},
                {title:'产品名称',dataIndex:'name'},
                {title:'规格型号',dataIndex:'spec',slots: {customRender: 'spec'}},
            ]" :row-selection="{
                selectedRowKeys:cpState.pks,onChange:(e)=>{cpState.pks = e} ,
                getCheckboxProps: (record) => ({ disabled: record.kd_checked }),
            }">
            <template #image="{record}">
                <div v-if="record.images && record.images.length > 0">
                    <a-image :src="record.images[0]" width="30px"></a-image>
                </div>
            </template>
            <template #spec="{record}">
                <span>{{ record.kd_spec?.model }}</span>
            </template>
        </a-table>
        <kd-pager :page-data="cpState" :show-size="false" :show-jumper="true" :event="getProductList"></kd-pager>
    </a-modal>

</div>
</template>

<script>
import { useCheckProduct } from '@/models/addons/storage'
import tool from "@/util/tool";
import {watch} from "vue";
export default {
    name: "com-check-product",
    props:{
        index:{
            type:Number
        },
        checkedData:{
            type:[Object,Array]
        }
    },
    setup(props,context){
        let { cpaState,cpState,getProductList,getProductListByAll,showProductByPage } = useCheckProduct()
        if( props.checkedData ){
            let arr = []
            props.checkedData.forEach(item=>arr.push(item.sku_id))
            cpaState.checkedData = arr
        }
        getProductListByAll()

        function selectProduct(data){
            context.emit("success", {data:[data],index:props.index})
        }
        function sureSelectProduct(){
            if( !cpState.pks || cpState.pks.length === 0 ){
                tool.message("请选择产品信息","warning")
                return
            }
            let temp = []
            cpState.list.forEach(item=>{
                if( cpState.pks.includes(item.kd_spec_id) ){
                    temp.push(item)
                }
            })
            cpState.show = false
            context.emit('success', {data:temp,index:props.index})
        }

        return {
            cpaState,cpState,getProductList,getProductListByAll,selectProduct,showProductByPage,sureSelectProduct
        }
    }
}
</script>

<style scoped lang="scss">
.ccp-con{
    position: relative;
}
.ccp-search{
    display: flex;
    position: relative;
    i{
        position: absolute;
        cursor: pointer;
        z-index: 999;
        right: 8px;
        top: 8px;
    }
}
.ccp-search /deep/ .ant-input{
    border: none !important;
}
</style>
